import { useMemo } from "react"
import { getCanonicalPageId, getPageProperty } from "notion-utils"
import { ExtendedRecordMap } from "notion-types"
import { useI18next } from "gatsby-plugin-react-i18next"

type Properties =
	| "Article Title"
	| "Cover Image"
	| "Categories"
	| "Time to Read"
	| "Author Image"
	| "Author"
	| "Excerpt"
	| "Author Bio"
	| "Published Date"
	| "Featured Image"
	| "External Link"
	| "Article Name English"

export type NotionArticle = ExtendedRecordMap & {
	id: string
}
export type Article = {
	link: string
	externalLink: string
	image: string
	title: string
	categories: string[]
	timeToRead: string
	author: {
		name: string
		image: string
		bio: string
	}
	excerpt: string
	publishedDate: number
	featuredImage: string
}

type P = {
	article: NotionArticle
}

export const useArticle = ({ article }: P): Article => {
	const { language } = useI18next()
	
	const link = useMemo(
		() =>
			{
				if (language === "en") {
					return `posts/${getCanonicalPageId(article.id, article as any, {
						uuid: false,
					})}`
				} else {
					return "posts/" +
						getProperty("Article Name English", article)
							?.toLowerCase()
							.replace(/[.,$’]/g, "")
							.split(" ")
							.join("-") ?? ""
				}	
			},
		[article]
	)

	const externalLink = useMemo(
		() => getProperty("External Link", article) ?? "",
		[article]
	)

	const image = useMemo(
		() => getProperty("Cover Image", article) ?? "",
		[article]
	)

	const title = useMemo(
		() => getProperty("Article Title", article) ?? "",
		[article]
	)

	const categories = useMemo(
		() => getProperty("Categories", article) ?? [],
		[getProperty]
	)

	const timeToRead = useMemo(
		() => getProperty("Time to Read", article) ?? "Few minutes",
		[article]
	)

	const excerpt = useMemo(() => getProperty("Excerpt", article), [article])

	const author = useMemo(() => {
		const authorName = getProperty("Author", article)
		const authorImage = getProperty("Author Image", article)
		const authorBio = getProperty("Author Bio", article)

		return {
			name: authorName ?? "Polymerize Team",
			image: authorImage ?? "/favicon/favicon-32x32.png",
			bio: authorBio ?? "AI catalyst for material innovation.",
		}
	}, [article])

	const publishedDate = useMemo(
		() => getProperty("Published Date", article),
		[article]
	)

	const featuredImage = useMemo(
		() => getProperty("Featured Image", article) ?? "",
		[article]
	)

	return {
		link,
		externalLink,
		image,
		title,
		categories,
		timeToRead,
		author,
		excerpt,
		publishedDate,
		featuredImage,
	}
}

export function getProperty(
	name: "Article Title",
	article: NotionArticle
): string
export function getProperty(name: "Cover Image", article: NotionArticle): string
export function getProperty(name: "External Link", article: NotionArticle): string
export function getProperty(
	name: "Time to Read",
	article: NotionArticle
): string
export function getProperty(
	name: "Categories",
	article: NotionArticle
): string[]
export function getProperty(name: "Author", article: NotionArticle): string
export function getProperty(
	name: "Author Image",
	article: NotionArticle
): string
export function getProperty(name: "Excerpt", article: NotionArticle): string
export function getProperty(name: "Author Bio", article: NotionArticle): string
export function getProperty(name: "Article Name English", article: NotionArticle): string
export function getProperty(
	name: "Published Date",
	article: NotionArticle
): number
export function getProperty(
	name: "Featured Image",
	article: NotionArticle
): string
export function getProperty(name: Properties, article: NotionArticle) {
	const block = article.block[article.id]?.value as any
	return getPageProperty(name, block, article as any)
}
