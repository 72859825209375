import React, { ComponentPropsWithRef, memo } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithRef<"input"> & {
	ignoreClasses?: string
	isError?: boolean
	desc?: string
	descClass?: string
	containerClass?: string
}

export const Checkbox = memo(
	({
		desc,
		ignoreClasses,
		isError,
		className = "",
		descClass = "",
		containerClass = "",
		...props
	}: P) => {
		const inputId = Math.random().toString(36)

		return (
			<div
				className={mergeClasses({
					classes: ["flex justify-items-start gap-3", containerClass],
				})}
			>
				<input
					id={inputId}
					className={mergeClasses({
						classes: [
							"rounded-md px-6 py-3 border border-gray-300 disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer",
							className,
							isError ? "outline-red-400 outline-1 outline text-red-400" : "",
						],
						ignore: ignoreClasses,
					})}
					type="checkbox"
					{...props}
				/>

				{desc && (
					<label
						htmlFor={inputId}
						className={mergeClasses({
							classes: [
								"text-sm font-medium text-gray-900 select-none cursor-pointer",
								descClass,
								isError ? "outline-red-400 outline-1 outline text-red-400" : "",
							],
						})}
					>
						{desc}
					</label>
				)}
			</div>
		)
	}
)

Checkbox.displayName = "Default Checkbox Component"
