import { API_URL } from "./utils"

export * from "./download-document"
export * from "./utils"

type GeneralResponse = {
	result: {
		status: "Success" | "Failed"
		message: string
	}
}

export type GetIPDetailsResponse = {
  city: string;
  country: string;
  ip: string;
  org: string;
  postal: string;
  region: string;
  timezone: string;
  error?: boolean;
};
export const getIPDetails = async () => {
  const response = await fetch(
    `https://ipapi.co/json/?key=${process.env.GATSBY_IPAPI_KEY}`,
    {
      method: "POST",
    },
  );
  const data: GetIPDetailsResponse = await response.json();
  return data;
};

export type ConnectTrialRegistrationPayload = {
	email: string
	organization: string
	job_title: string
}
export const connectTrialRegistration = async (
	payload: ConnectTrialRegistrationPayload
) => {
	const response = await fetch(`${API_URL}/connect_trial_enquiry`, {
		headers: {
			"Content-Type": "application/json",
		},
		method: "POST",
		body: JSON.stringify(payload),
	})
	const data: GeneralResponse = await response.json()
	return data
}
