import React, {
	ComponentPropsWithRef,
	forwardRef,
	memo,
	NamedExoticComponent,
	useEffect,
	useState,
} from "react"
import { useTranslation } from "react-i18next"
import { mergeClasses } from "utils/merge-classes"

type P = ComponentPropsWithRef<"input"> & {
	ignoreClasses?: string
	validator?: (val: string) => boolean
	isError?: boolean
}

export const Input: NamedExoticComponent<P> = memo(
	forwardRef(
		(
			{
				className = "",
				ignoreClasses = "",
				validator,
				isError = false,
				required = false,
				onChange,
				...props
			},
			ref
		) => {

			const { t } = useTranslation()
			const requiredTooltip = t("common.tooltips.requiredFieldTooltip")
			const [title, setTitle] = useState('')

			useEffect(() => {
				if (required) {
					setTitle(requiredTooltip)
				}
			}, [required, t])

			const handleChange = (e:any) => {
				if (required) {
					setTitle(e.target.value ? "" : requiredTooltip)
				}
				if (!validator && onChange) onChange(e);
				if (validator && validator(e.target.value) && onChange) onChange(e);
			}
			return (
				<input
					ref={ref}
					maxLength={255}
					onChange={handleChange}
					title={title}
					required={required}
					className={mergeClasses({
						classes: [
							"rounded-md px-6 py-3 border border-gray-300 disabled:cursor-not-allowed disabled:opacity-50 w-full",
							className,
							isError ? "outline-red-400 outline-1 outline text-red-400 bg-[#FCDEDE]" : "",
						],
						ignore: ignoreClasses,
					})}
					{...props}
				/>
			)
		}
	)
)

Input.displayName = "Default Input Component"
