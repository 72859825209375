import { getCanonicalPageId, getPageProperty } from "notion-utils"
import { NotionArticle } from "./article-utils"

type JobType = "Fulltime" | "Internship" | "Freelance"
export type Team = "Engineering"

type Properties =
	| "Position"
	| "Excerpt"
	| "Location"
	| "Experience"
	| "Job Type"
	| "Team"
	| "Published"

export type Job = {
	position: string
	excerpt: string
	location: string[]
	experience: number
	job_type: JobType
	team: Team
	published: boolean
	slug: string
}

export const transformJob = (job: NotionArticle): Job => {
	const position = getProperty("Position", job)
	const excerpt = getProperty("Excerpt", job)
	const location = getProperty("Location", job)
	const experience = getProperty("Experience", job)
	const job_type = getProperty("Job Type", job)
	const team = getProperty("Team", job)
	const published = getProperty("Published", job)
	const slug = getCanonicalPageId(job.id, job as any, { uuid: false }) as string
	return {
		position,
		excerpt,
		location,
		experience,
		job_type,
		team,
		published,
		slug,
	}
}

export const transformJobs = (jobs: NotionArticle[]): Job[] => {
	return jobs.map<Job>((job) => transformJob(job))
}

function getProperty(name: "Position", post: NotionArticle): string
function getProperty(name: "Excerpt", post: NotionArticle): string
function getProperty(name: "Location", post: NotionArticle): string[]
function getProperty(name: "Experience", post: NotionArticle): number
function getProperty(name: "Job Type", post: NotionArticle): JobType
function getProperty(name: "Team", post: NotionArticle): Team
function getProperty(name: "Published", post: NotionArticle): boolean
function getProperty(name: Properties, post: NotionArticle) {
	const block = post.block[post.id]?.value as any
	return getPageProperty(name, block, post as any)
}
