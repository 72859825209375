import React, { ComponentPropsWithoutRef } from "react";
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<'div'> & {
    height?: number
};

export const Divider = ({ height = 1, className = '', ...props }: P) => {
    return (
        <div
            style={{ height }}
            className={mergeClasses({ classes: ['bg-gray-200 w-full', className] })}
            {...props}
        >
        </div>
    )
};
