import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithoutRef<"svg">

export const SuccessIllustration = ({ className = "", ...props }: P) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 194 145"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<path
				fill="url(#paint0_linear_6029_39697)"
				d="M182.377 16.357C178.794 6.951 169.666.262 158.974.262c-10.693 0-19.821 6.68-23.403 16.088-6.703 1.752-11.654 7.826-11.654 15.06 0 8.604 7 15.574 15.635 15.574h38.812c8.635 0 15.636-6.97 15.636-15.574 0-7.219-4.936-13.293-11.623-15.053z"
			></path>
			<path
				fill="url(#paint1_linear_6029_39697)"
				d="M53.777 100.427c-3.236-8.495-11.479-14.536-21.135-14.536-9.656 0-17.899 6.034-21.134 14.529-6.054 1.582-10.525 7.067-10.525 13.6 0 7.771 6.322 14.065 14.12 14.065h35.05c7.798 0 14.12-6.294 14.12-14.065 0-6.519-4.457-12.004-10.496-13.593z"
			></path>
			<path
				fill="#F8CCA9"
				d="M36.592 42.692c0-8.655 7.016-15.671 15.67-15.671h107.253c8.655 0 15.671 7.016 15.671 15.67v49.59c0 8.655-7.016 15.671-15.671 15.671H52.263c-8.655 0-15.671-7.016-15.671-15.671V42.69z"
			></path>
			<path
				fill="#FCEADC"
				d="M42.663 44.212c0-5.77 4.677-10.448 10.447-10.448h105.56c5.77 0 10.447 4.678 10.447 10.448V90.76c0 5.77-4.677 10.447-10.447 10.447H53.11c-5.77 0-10.447-4.677-10.447-10.447V44.212z"
			></path>
			<path
				fill="#F39244"
				d="M80.165 107.952h51.162l5.443 16.523H74.723l5.442-16.523z"
			></path>
			<path
				fill="#FFBD88"
				d="M70.799 124.981a3.54 3.54 0 013.54-3.541h63.397a3.54 3.54 0 010 7.081H74.34a3.54 3.54 0 01-3.541-3.54z"
			></path>
			<path
				fill="#1753E2"
				d="M127.493 50.916a74.474 74.474 0 01-11.047-3.809 75.873 75.873 0 01-10.145-5.202l-.918-.551-.901.567a75.85 75.85 0 01-10.146 5.203 74.51 74.51 0 01-11.063 3.792l-1.251.308v13.517c0 21.702 22.577 29.822 22.794 29.903l.567.194.567-.194c.234 0 22.794-8.185 22.794-29.903V51.224l-1.251-.308zm-7.626 10.778L101.228 79.28 90.682 69.02a2.26 2.26 0 01-.691-1.62c0-.609.249-1.192.691-1.622a2.396 2.396 0 011.669-.67c.626 0 1.226.24 1.668.67l7.259 7.18 15.335-14.586a2.36 2.36 0 01.766-.497 2.418 2.418 0 012.571.497 2.245 2.245 0 010 3.242l-.083.08z"
			></path>
			<path
				fill="#265AFF"
				d="M186.874 88.623h-27.733a3.963 3.963 0 00-3.962 3.962v47.542a3.962 3.962 0 003.962 3.961h27.733a3.962 3.962 0 003.961-3.961V92.584a3.961 3.961 0 00-3.961-3.961zm-11.886 51.504h-3.962v-3.962h3.962v3.962zm-15.847-7.924V92.585h27.733v39.618h-27.733z"
			></path>
			<path
				fill="#DEE9FD"
				d="M158.42 92.044h29.174v40.519H158.42V92.044z"
			></path>
			<path
				fill="#F39244"
				d="M181.854 110.227h-1.688v-5.014c0-3.677-3.039-6.686-6.754-6.686-3.714 0-6.753 3.009-6.753 6.686v5.014h-1.688c-.844 0-1.688.836-1.688 1.671v11.7c0 .836.844 1.672 1.688 1.672h16.883c.844 0 1.688-.836 1.688-1.672v-11.7c0-.835-.844-1.671-1.688-1.671zm-6.753 11.7h-3.377l.675-3.677c-.844-.335-1.519-1.337-1.519-2.173 0-1.337 1.182-2.507 2.532-2.507 1.351 0 2.533 1.17 2.533 2.507 0 1.003-.507 1.838-1.52 2.173l.676 3.677zm1.688-11.7h-6.753v-5.014c0-1.839 1.519-3.343 3.376-3.343 1.858 0 3.377 1.504 3.377 3.343v5.014z"
			></path>
			<path
				fill="#FFBD88"
				d="M58.928 10.592c.229.228.358.536.358.859v23.43c0 .672-.544 1.215-1.216 1.215H33.755a1.214 1.214 0 01-1.216-1.216V3.27c0-.672.544-1.215 1.216-1.215H49.89c.323 0 .635.13.863.357l8.175 8.18zm-2.446 1.417l-7.15-7.15v7.15h7.15z"
			></path>
			<path
				fill="#FCEADC"
				d="M51.634 15.455c.228.228.357.536.357.859v23.43c0 .672-.543 1.215-1.216 1.215H26.461a1.214 1.214 0 01-1.216-1.216V8.133c0-.672.543-1.215 1.216-1.215h16.135c.323 0 .634.13.862.357l8.176 8.18zm-2.447 1.417l-7.15-7.15v7.15h7.15z"
			></path>
			<path
				fill="#4370FF"
				fillRule="evenodd"
				d="M38.618 21.507c.52 0 1.387.188 2.204.397.835.213 1.677.465 2.172.617.208.065.391.183.53.341.139.159.228.35.256.554.449 3.175-.592 5.528-1.855 7.085a8.647 8.647 0 01-1.894 1.74c-.29.193-.56.341-.79.443-.21.093-.436.17-.623.17s-.412-.077-.624-.17a5.465 5.465 0 01-.789-.443 8.645 8.645 0 01-1.894-1.74c-1.263-1.557-2.304-3.91-1.855-7.085.028-.204.117-.396.256-.554.139-.159.322-.277.53-.341a49.231 49.231 0 012.172-.617c.817-.208 1.685-.397 2.204-.397zm0 3.546c-.267 0-.525.088-.729.25-.204.162-.34.386-.385.634-.045.248.004.502.14.719.134.216.346.38.598.464l-.29 1.412a.334.334 0 00.08.293c.035.04.079.072.129.095.05.022.105.033.16.033h.594a.396.396 0 00.29-.129.334.334 0 00.079-.293l-.29-1.41c.252-.084.464-.249.599-.465.135-.217.184-.471.14-.719a1.052 1.052 0 00-.386-.634 1.172 1.172 0 00-.729-.25z"
				clipRule="evenodd"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6029_39697"
					x1="158.958"
					x2="158.958"
					y1="7.946"
					y2="43.91"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC498"></stop>
					<stop offset="1" stopColor="#AADABD" stopOpacity="0.22"></stop>
					<stop offset="1" stopColor="#AADABD" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6029_39697"
					x1="32.805"
					x2="32.805"
					y1="89.26"
					y2="122.057"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC498"></stop>
					<stop offset="1" stopColor="#AADABD" stopOpacity="0.25"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
