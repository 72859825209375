import React, { ComponentPropsWithRef, forwardRef, memo, NamedExoticComponent } from "react";
import { mergeClasses } from "../../../utils/merge-classes";

type P = ComponentPropsWithRef<"textarea"> & {
	ignoreClasses?: string;
};

export const Textarea: NamedExoticComponent<P> = memo(
	forwardRef(({ className = "", ignoreClasses = "", children, ...props }, ref) => {
		return (
			<textarea
				ref={ref}
				maxLength={1200}
				className={mergeClasses({
					classes: [
						"h-40 max-h-60 min-h-[80px] rounded-md p-5 border border-gray-300 disabled:cursor-not-allowed disabled:opacity-50",
						className,
					],
					ignore: ignoreClasses,
				})}
				{...props}
			>
				{children}
			</textarea>
		);
	}),
);

Textarea.displayName = "Default Textarea Component";
