export const API_URL = `${process.env.GATSBY_BACKEND_API_V1}`

export type ApiResponse<ExtraPropInResult, Data = void> =
	| {
			result: {
				message: string
				status: "Success"
				data: Data
			} & ExtraPropInResult
	  }
	| {
			result: {
				message: string
				status: "Failed"
			}
	  }

export const get = async <Data, ExtraPropInResult = void>(
	url: string,
	options?: {
		headers: RequestInit["headers"]
	}
): Promise<ApiResponse<ExtraPropInResult, Data>> => {
	const response = await fetch(`${API_URL}${url}`, {
		method: "GET",
		headers: options?.headers,
	})
	const data: ApiResponse<ExtraPropInResult, Data> = await response.json()
	return data
}

export const post = async <Body, Data, ExtraPropInResult = void>(
	url: string,
	options: {
		headers?: RequestInit["headers"]
		body: Body
	}
): Promise<ApiResponse<ExtraPropInResult, Data>> => {
	const response = await fetch(`${API_URL}${url}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(options.body),
	})
	const data: ApiResponse<ExtraPropInResult, Data> = await response.json()
	return data
}
