import React from "react"
import { Tooltip } from "components/library"
import { NewsroomIcon } from "components/library/icons/header/newsroomIcon";
import { useTranslation } from "react-i18next";

export const PublicNoticeButton = () => {
    const { t } = useTranslation()
	return (
    <a
      href={
        '/public-notice/'
      }
      rel="noopener noreferrer"
      className="w-10 fixed right-0 top-48 sm:top-46 rounded-l-md bg-white cursor-pointer h-10 flex items-center justify-center z-10 shadow-lg"
    >
      <Tooltip content={t('footer.navigation-items.4.subitems.7')} direction="left">
        <NewsroomIcon size={28} />
      </Tooltip>
    </a>
  );
}
