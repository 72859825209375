import React from "react"
import { FooterNewsletter } from "./footer-newsletter"
import { TranslationKey } from "types/translation"
import { NavigationSection } from "./footer-navigation-section"
import { BLOG_BASE_URL } from "variables"
// import { LAB_BASE_URL } from "variables"
// import { Link } from "gatsby-plugin-react-i18next"

export type SubItem = {
	name: TranslationKey
	href: string
	newWindow?: boolean
}

const products: SubItem[] = [
	// {
	// 	name: "footer.navigation-items.1.subitems.1",
	// 	href: "/products/overview/",
	// },
	// {
	// 	name: "footer.navigation-items.1.subitems.2",
	// 	href: "/products/material-informatics/",
	// },
	// {
	// 	name: "footer.navigation-items.1.subitems.3",
	// 	href: "/connect/",
	// },
	// {
	// 	name: "footer.navigation-items.1.subitems.4",
	// 	href: "/pricing/",
	// },
	// {
	// 	name: "footer.navigation-items.1.subitems.5",
	// 	href: LAB_BASE_URL,
	// 	newWindow: true,
	// },
	{
		name: "footer.navigation-items.1.subitems.6",
		href: "/products/data-management/",
	},
	{
		name: "footer.navigation-items.1.subitems.7",
		href: "/products/ai/",
	},
	{
		name: "footer.navigation-items.1.subitems.8",
		href: "/products/doe/",
	},
]
const solutions: SubItem[] = [
	{
		name: 'footer.navigation-items.1.subitems.1',
		href: '/solutions/overview'
	},
	{
		name: "footer.navigation-items.2.subitems.1",
		href: "/solutions/research-and-development/",
	},
	{
		name: "footer.navigation-items.2.subitems.2",
		href: "/solutions/technology-scouts/",
	},
	{
		name: "footer.navigation-items.2.subitems.3",
		href: "/solutions/researchers/",
	},
]
const resources: SubItem[] = [
	{
		name: 'common.words.applications',
		href: '/applications/'
	},
	{
		name: "footer.navigation-items.3.subitems.4",
		href: "/case-studies-n-white-papers/",
	},
	{
		name: "footer.navigation-items.3.subitems.5",
		href: "/case-studies-n-white-papers/",
	},
	{
		name: "footer.navigation-items.3.subitems.3",
		href: BLOG_BASE_URL,
		newWindow: true,
	},
	{
		name: 'newsletter-page.heading',
		href: '/newsletter/'
	},
	{
		name: "footer.navigation-items.3.subitems.1",
		href: "/support/",
	},
	{
		name: "footer.navigation-items.3.subitems.2",
		href: "/faqs/",
	},
]
const company: SubItem[] = [
	{
		name: "footer.navigation-items.4.subitems.1",
		href: "/about-us/",
	},
	{
		name: "footer.navigation-items.4.subitems.2",
		href: "/careers/",
	},
	{
		name: "footer.navigation-items.4.subitems.3",
		href: "/contact-us/",
	},
	// {
	// 	name: "footer.navigation-items.4.subitems.4",
	// 	href: "/security/",
	// },
	{
		name: "footer.navigation-items.4.subitems.6",
		href: "/events/",
	},
	{
		name: "footer.navigation-items.4.subitems.5",
		href: "/newsroom/",
	},
]

process.env.GATSBY_LANGUAGE_SELECTED === "ja" && company.push(	{
		name: "footer.navigation-items.4.subitems.7",
		href: "/public-notice/",
	},)

export const FooterNavigation = () => {
	return (
		<div className="grid grid-cols-2 gap-x-4 gap-y-20 lg:flex lg:justify-between">
			<NavigationSection
				title="footer.navigation-items.1.title"
				subItems={products}
				// extra={
				// 	<Link
				// 		className="px-2 py-1 cursor-pointer rounded max-w-max bg-gradient-to-r from-rose-400 to-orange-300 text-white text-sm font-medium mt-4"
				// 		to="/events/chem-expo"
				// 	>
				// 		Chem Expo 2022
				// 	</Link>
				// }
			/>
			<NavigationSection
				title="footer.navigation-items.2.title"
				subItems={solutions}
			/>
			<NavigationSection
				title="footer.navigation-items.3.title"
				subItems={resources}
			/>
			<NavigationSection
				title="footer.navigation-items.4.title"
				subItems={company}
			/>

			<FooterNewsletter />
		</div>
	)
}
