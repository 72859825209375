import React, {
  ComponentPropsWithRef,
  forwardRef,
  memo,
  NamedExoticComponent,
  useEffect,
  useState,
} from "react"
import { mergeClasses } from "utils/merge-classes"
import { ChevronDown } from "react-feather"
import { useTranslation } from "react-i18next"

type P = ComponentPropsWithRef<"select"> & {
  ignoreClasses?: string
  isError?: boolean
}

export const Select: NamedExoticComponent<P> = memo(
  forwardRef(
    (
      {
        className = "",
        ignoreClasses = "",
        children,
        placeholder,
        isError = false,
        required = false,
        ...props
      },
      ref,
    ) => {

      const { t } = useTranslation()
      const requiredTooltip = t("common.tooltips.requiredSelectTooltip")
      const [title, setTitle] = useState('')

      useEffect(() => {
        if (required) {
          setTitle(requiredTooltip)
        }
      }, [required, t])

      const handleChange = (e) => {
        if (required) {
          setTitle(e.target.value ? "" : requiredTooltip)
        }
      }

      return (
        <div className="relative">
          <ChevronDown
            className={mergeClasses({
              classes: [
                "absolute right-5 pointer-events-none top-1/2 -translate-y-1/2 z-20",
                !!props.value ? "text-gray-700" : "text-gray-400",
              ],
            })}
          />
          <select
            onChange={handleChange}
            title={title}
            ref={ref}
            className={mergeClasses({
              classes: [
                "rounded-md px-6 py-3 w-full pr-10 border bg-white appearance-none border-gray-300 disabled:cursor-not-allowed disabled:opacity-50 relative",
                !!props.value ? "" : "text-gray-400",
                isError
                  ? "outline-red-400 outline-1 outline text-red-400 bg-[#FCDEDE]"
                  : "",
                className,
              ],
              ignore: ignoreClasses,
            })}
            {...props}
          >
            {placeholder && (
              <option className="text-gray-200" value="" disabled>
                {placeholder}
              </option>
            )}
            {children}
          </select>
        </div>
      )
    },
  ),
)

Select.displayName = "Default Select Component"
