import React, { ReactNode } from 'react'
import { TranslationKey } from 'types/translation'
import { Link, Trans } from 'gatsby-plugin-react-i18next'
import { SubItem } from './footer-navigation'

type P = {
  title: TranslationKey
  subItems: SubItem[]
  extra?: ReactNode
}

export const NavigationSection = ({ title, subItems, extra }: P) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="uppercase font-bold">
        <Trans i18nKey={`${title}`} />
      </h3>

      <ul className="flex flex-col gap-2">
        {subItems.map((subItem) => {
          return (
            <li
              key={subItem.name}
              className="transition duration-[300ms] hover:text-[#1B55CE]"
            >
              {subItem.newWindow ? (
                <a href={subItem.href} target="_blank">
                  <Trans i18nKey={`${subItem.name}`} />
                </a>
              ) : (
                <Link to={subItem.href}>
                  <Trans i18nKey={`${subItem.name}`} />
                </Link>
              )}
            </li>
          )
        })}
      </ul>

      {extra}
    </div>
  )
}
