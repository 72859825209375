import React, { Dispatch, SetStateAction, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PrimaryBtn } from "components/library"
import { SuccessIllustration } from "components/library/illustrations/success-illustration";

type P = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	mode: "error" | "success"
	showIllustration?: boolean
	success: {
		title: string
		desc?: string
		cta?: string
	}
	error: {
		title: string
		desc?: string
		cta?: string
	}
}

export const ResponseModal = ({
	open,
	setOpen,
	mode,
	error,
	success,
	showIllustration = true,
}: P) => {
	const { t } = useTranslation()

	useEffect(() => {
		if (open) document.body.style.overflow = "hidden"
		if (!open) document.body.style.overflow = ""
	}, [open])

	if (!open) return null

	return (
		<div
			className={`flex justify-center items-center fixed z-[200] left-0 top-0 w-full h-full overflow-auto bg-black/50`}
		>
			<div className="flex flex-col items-center text-center gap-8 w-[95%] max-w-[600px] font-work-sans rounded-md shadow bg-neutral p-8">
				{showIllustration && <SuccessIllustration />}

				<div className="flex flex-col items-center gap-2 w-full">
					<h3 className="text-2xl sm:text-3xl">
						{mode === "success" ? success.title : error.title}
					</h3>
					{mode === "success" && success.desc && (
						<p className="w-[80%]">{success.desc}</p>
					)}
					{mode === "error" && error.desc && (
						<p className="w-[80%]">{error.desc}</p>
					)}
				</div>
				<PrimaryBtn
					onClick={() => setOpen(false)}
					className="w-full max-w-[300px]"
				>
					{mode === "success"
						? success.cta ?? t("common.cta.okay")
						: error.cta ?? t("common.cta.okay")}
				</PrimaryBtn>
			</div>
		</div>
	)
}
