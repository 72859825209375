import React, { useMemo } from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

export const FooterCopyright = () => {
	const { t } = useTranslation()
	const year = useMemo(() => new Date().getFullYear(), [])

	return (
		<div>
			<div className="border-t border-t-neutral-400 flex flex-col-reverse gap-4 sm:flex-row sm:justify-between pt-5 sm:items-center">
				<p className="text-xs">
					{t("common.words.copyright")} &copy; {year} Polymerize.{" "}
					{t("common.words.all-rights-reserved")}.
				</p>

				<ul className="text-sm flex gap-4 items-center font-semibold">
					<li>
						<a
							href="https://polymerize.freshstatus.io/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("common.words.status")}
						</a>
					</li>
					<li>
						<Link to="/security/">{t("common.words.security")}</Link>
					</li>
					<li>
						<Link to="/privacy-policy/">{t("common.words.privacy")}</Link>
					</li>
					<li>
						<Link to="/terms-of-use/">{t("common.words.terms")}</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}
