import React, { ComponentPropsWithoutRef } from "react"
import { Container } from "components/library"
import { FooterCopyright } from "./footer-copyright"
import { FooterHeader } from "./footer-header"
import { FooterNavigation } from "./footer-navigation"
import { mergeClasses } from "utils"

type P = ComponentPropsWithoutRef<"footer">

export const Footer = ({ className = "", ...props }: P) => {
	return (
		<footer
			className={mergeClasses({
				classes: ["bg-neutral-200 w-full", className],
			})}
			{...props}
		>
			<Container className="flex flex-col py-12 sm:py-16 gap-16">
				<FooterHeader />
				<FooterNavigation />
				<FooterCopyright />
			</Container>
		</footer>
	)
}
