import React, {
	ComponentPropsWithRef,
	forwardRef,
	memo,
	NamedExoticComponent,
	useState,
} from "react"
import { mergeClasses } from "utils/merge-classes"

type Directions = "top" | "right" | "bottom" | "left"

type P = ComponentPropsWithRef<"div"> & {
	direction?: Directions
	ignoreClasses?: string
	content: React.ReactNode | string
}

function getTooltipPosition(direction: Directions) {
	if (direction === "left") {
		return {
			right: "180%",
			top: "50%",
			transform: "translateY(-50%)",
		}
	}
	if (direction === "right") {
		return {
			left: "180%",
			top: "50%",
			transform: "translateY(-50%)",
		}
	}

	if (direction === "bottom") {
		return {
			left: "50%",
			top: "140%",
			transform: "translateX(-50%)",
		}
	}

	return {
		left: "50%",
		bottom: "140%",
		transform: "translateX(-50%)",
	}
}
function getArrowPosition(direction: Directions) {
	if (direction === "left") {
		return {
			right: "-6px",
			top: "50%",
			transform: "translateY(-50%) rotate(45deg)",
		}
	}
	if (direction === "right") {
		return {
			left: "-6px",
			top: "50%",
			transform: "translateY(-50%) rotate(45deg)",
		}
	}

	if (direction === "bottom") {
		return {
			left: "50%",
			top: "-6px",
			transform: "translateX(-50%) rotate(45deg)",
		}
	}

	return {
		left: "50%",
		bottom: "-6px",
		transform: "translateX(-50%) rotate(45deg)",
	}
}

export const Tooltip: NamedExoticComponent<P> = memo(
	forwardRef(
		(
			{
				className = "",
				ignoreClasses = "",
				direction = "top",
				children,
				content,
				...props
			},
			ref
		) => {
			const [visible, setVisible] = useState(false)

			return (
				<div
					ref={ref}
					className="relative"
					style={{
						color: "#1753E2",
					}}
				>
					{visible && (
						<div
							role="tooltip"
							style={getTooltipPosition(direction)}
							className={mergeClasses({
								classes: [
									"absolute z-[500] py-2 w-auto min-w-max px-3 text-sm font-medium  bg-current rounded-lg shadow-md text-center",
									className,
								],
								ignore: ignoreClasses,
							})}
							{...props}
						>
							<div
								className="h-3 w-3 absolute bg-current"
								style={getArrowPosition(direction)}
							></div>
							<span className="text-white">{content}</span>
						</div>
					)}

					<div
						onMouseEnter={() => setVisible(true)}
						onMouseLeave={() => setVisible(false)}
					>
						{children}
					</div>
				</div>
			)
		}
	)
)

Tooltip.displayName = "Default Tooltip Component"
