import React from "react"

type P = {
    message: string
}

export const InputError = ({ message }: P) => {
    return (
        <span className='text-red-400 text-sm'>{message}</span>
    )
}
